// ==========================================================================
// DO WHEN - A jQuery plugin to do stuff when you want
// https://github.com/dkeeghan/jQuery-doWhen
// ==========================================================================

(function(NAMESPACE, $) {

    'use strict';
    NAMESPACE.floatingFeedbackForm = (function() {

        var SELECTORS,
            toggleInternalForms,
            toggleButtons,
            resetIcons,
            detectFieldChanges,
            onSubmit,
            toggleIcons,
            init;

        function getDynamicURLPath() {
            var localUrl = '../../../assets/img/svgs/';
            var element = document.getElementById('your-feedback');
            var prodUrl = element ? element.getAttribute('data-img-url') : null;
            var origin = window.location.origin;

            if (origin === 'http://localhost:8080') {
                return localUrl;
            } else {
                return prodUrl + 'img/svgs/';
            }
        }

        function getDynamicAPI() {
            var localApi = 'https://sandoxi3.getsandbox.com:443/api/fba/feedbackassist';
            var origin = window.location.origin;

            if (origin === 'http://localhost:8080') {
                return localApi;
            } else {
                return origin + '/api/fba/feedbackassist';
            }
        }


        SELECTORS = {
            BTN: '#idFloatingBtn',
            BTN_TOGGLE: '#idFloatingBtnToggle',
            BTN_OPEN: '#idBtnOpen',
            BTN_CLOSE: '#idBtnClose',
            BTN_SHUTDOWN: '#idFloatingBtnShutdown',
            NEXT_BTN: '.nextBtn',
            SUBMIT_BTN: '.submitBtn',
            PHONE_FIELD: '.contact-phone-field',
            EMAIL_FIELD: '.contact-email-field',
            FORM_SECTION: '.cm-floating-form',
            SCREEN_OVERLAY: '.overlay-for-feedbackwidget',
            ENQUIRYSUBMIT: '.enquiry-form-submit',
            FORM_IDS: ['#give-complement', '#make-complain'],
            iconUrls:{
                feedback:{
                    active:{
                        dynamicPath: getDynamicURLPath() + 'icon-feedback-active.svg'
                    },
                    nonActive:{
                        dynamicPath: getDynamicURLPath() + 'icon-feedback-updated.svg'
                    }

                },
                complaint: {
                    active: {
                        dynamicPath: getDynamicURLPath() + 'icon-complaint-active.svg'
                    },
                    nonActive: {
                        dynamicPath: getDynamicURLPath() + 'icon-complaint.svg'
                    }
                },
                thankYouIcon:{
                    dynamicPath: getDynamicURLPath() + 'Thank-you-icon.svg'
                }
            }

        };

        function validPhoneNUmber(phoneNumber) {
            var phonePattern = /^0[0-8]\d{8}$/g;
            //phone number is not valid. Please notice that you don't need to check if it's empty or null since Regex checks it for you anyways
            if (!phonePattern.test(phoneNumber)) {
                return false;
            }
        }

        function validText(fieldValue) {
            return  typeof fieldValue === 'string' && fieldValue.trim().length > 0;
        }

        function validEmailAddress(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };

        /*
        * Toggle buttons accordingly
        * */

        toggleButtons = function() {


            $(SELECTORS.NEXT_BTN).hide();
            $(SELECTORS.PHONE_FIELD).hide();
            $(SELECTORS.EMAIL_FIELD).hide();
        };

        /*
        * detect field changes and hide and show relevant fields
        * */


        detectFieldChanges = function() {

            $(SELECTORS.FORM_IDS[0] + '-contact-back-contact-back-yes').on('click', function() {
                $(SELECTORS.FORM_IDS[0]).find(SELECTORS.SUBMIT_BTN).hide();
                $(SELECTORS.FORM_IDS[0]).find(SELECTORS.NEXT_BTN).show();
            });

            $(SELECTORS.FORM_IDS[0] + '-contact-back-contact-back-no').on('click', function() {
                $(SELECTORS.FORM_IDS[0]).find(SELECTORS.SUBMIT_BTN).show();
                $(SELECTORS.FORM_IDS[0]).find(SELECTORS.NEXT_BTN).hide();
            });

            $(SELECTORS.FORM_IDS[0] + '-Key_ContactBy-Key_ContactBy-phone').on('click', function() {
                $(SELECTORS.FORM_IDS[0]).find('.contact-phone-field').show();
                $(SELECTORS.FORM_IDS[0]).find('.contact-email-field').hide();
            });

            $(SELECTORS.FORM_IDS[0] + '-Key_ContactBy-Key_ContactBy-email').on('click', function() {
				$(SELECTORS.FORM_IDS[0]).find('.contact-phone-field').hide();
				$(SELECTORS.FORM_IDS[0]).find('.contact-email-field').show();
			});

            $(SELECTORS.FORM_IDS[1] + '-contact-back-contact-back-yes').on('click', function() {
                $(SELECTORS.FORM_IDS[1]).find(SELECTORS.SUBMIT_BTN).hide();
                $(SELECTORS.FORM_IDS[1]).find(SELECTORS.NEXT_BTN).show();
            });

            $(SELECTORS.FORM_IDS[1] + '-contact-back-contact-back-no').on('click', function() {
                $(SELECTORS.FORM_IDS[1]).find(SELECTORS.SUBMIT_BTN).show();
                $(SELECTORS.FORM_IDS[1]).find(SELECTORS.NEXT_BTN).hide();
            });

            //show and hide phone and email fields based on user selection
            $(SELECTORS.FORM_IDS[1] + '-Key_ContactBy-Key_ContactBy-phone').on('click', function() {
                $(SELECTORS.FORM_IDS[1]).find('.contact-phone-field').show();
                $(SELECTORS.FORM_IDS[1]).find('.contact-email-field').hide();
            });

            $(SELECTORS.FORM_IDS[1] + '-Key_ContactBy-Key_ContactBy-email').on('click', function() {
                $(SELECTORS.FORM_IDS[1]).find('.contact-phone-field').hide();
                $(SELECTORS.FORM_IDS[1]).find('.contact-email-field').show();
            });


        };


        /*
        * on click of submit validate the form and call api
        *
        * */


        function getDataForAPI(form) {
            var formType = $(form).data('value');
            var data = [{
                key: 'Key_ReasonBy',
                name: 'Reason',
                required: true,
                value: ''
            }, {
                key: 'Key_ProductArea',
                name: 'Product area',
                required: true,
                value: '',
                fieldName: 'product-area',
                errorMessage: 'required'
            }, {
                key: 'Key_ContactMeBack',
                name: 'Contact me back',
                required: true,
                value: ''
            }, {
                key: 'Key_ContactBy',
                name: 'Contact by',
                required: false,
                value: '',
                fieldName: 'Key_ContactBy',
                errorMessage: 'required'
            }, {
                key: 'Key_ComplaintsDetail',
                name: 'feedback details',
                required: true,
                value: '',
                fieldName: 'fnc-details',
                errorMessage: 'required'
            }, {
                key: 'Key_DesiredAction',
                name: 'Desired action',
                required: false,
                value: ''
            }, {
                key: 'Key_FirstName',
                name: 'First name',
                required: false,
                value: '',
                fieldName: 'first-name',
                errorMessage: 'required'
            }, {
                key: 'Key_LastName',
                name: 'Last name',
                required: false,
                value: '',
                fieldName: 'last-name',
                errorMessage: 'required'
            }, {
                key: 'Key_ReferenceArea',
                name: 'Reference area',
                required: false,
                value: ''
            }, {
                key: 'Key_ContactDetail_Phone',
                name: 'Phone',
                required: false,
                value: '',
                fieldName: 'contact-phone',
                errorMessage: 'Valid phone required'

            }, {
                key: 'Key_ContactDetail_Email',
                name: 'Email',
                required: false,
                value: '',
                fieldName: 'contact-email',
                errorMessage: 'valid email required'
            }, {
                key: 'Key_GoogleCaptchaToken',
                name: 'Token',
                required: false,
                value: '',
                fieldName: 'GoogleCaptchaToken',
                errorMessage: 'recaptcha token is required'
            }];

            data[0].value = formType;
            data[1].value = $(form).find('#product-area').val();
            data[2].value = $(form).find('[name$="-contact-back"]:checked').val();

            data[4].value = $(form).find('#fnc-details').val();
            if ($(form).find('#like-to-hpn').val()) {
                data[5].value = $(form).find('#like-to-hpn').val();
            };

            data[6].value = $(form).find('#first-name').val();
            data[7].value = $(form).find('#last-name').val();
            if ($(form).find('#reference-num').val()) {
                data[8].value = $(form).find('#reference-num').val();
            };

            data[9].value = $(form).find('#contact-phone').val();
            data[10].value = $(form).find('#contact-email').val();
            data[11].value = $('#GoogleCaptchaToken').val();

            //when user select contact back yes make more fields mandatory
            if (data[2].value === 'yes') {
                data[3].required = true; //make contact by field required
                data[3].value = $(form).find('input[name$="-Key_ContactBy"]:checked').val();
                data[6].required = true;
                data[7].required = true;
                if (typeof(data[3].value) === 'string' && data[3].value.length > 0) {
                    if (data[3].value === 'phone') {
                        data[9].required = true;
                        data[10].required = false;
                    } else {
                        data[9].required = false;
                        data[10].required = true;
                    }
                }
            } else {
                data[3].required = false;
                data[6].required = false;
                data[7].required = false;
                data[9].required = false;
                data[10].required = false;
            }
            return data;
        };

        function validateForm(form) {

            var errorMessage = '';
            var errorFields = [];
            var apiData = {};
            var selectedField = '';


            //TODO - dont chek for phone and email if contact me back is no
            var data = getDataForAPI(form);


            data.forEach(function(field) {
                if (typeof(field.value) === 'string' && field.value.length <= 0) {
                    if (field.required === true) {
                        errorFields.push(field.name);
                            if (typeof field.fieldName === 'string') {
                                selectedField = $(form).find('[name="' + field.fieldName + '"]');
                                selectedField.addClass('has-error');
                                selectedField.closest('.ctrl-holder').next('.field-error-message').show();
                        }
                    };
                }

                //check if product area field is at index 0
                if (field.fieldName === 'product-area') {
                    selectedField = $(form).find('[name="' + field.fieldName + '"]');

                    const position  = $(selectedField).prop('selectedIndex');
                    if (position === 0) {
                        errorFields.push(field.name);
                        selectedField.closest('.ctrl-holder').next('.field-error-message').show();
                    } else {
                        selectedField.closest('.ctrl-holder').next('.field-error-message').hide();
                    }
                }

                if (field.fieldName === 'Key_ContactBy') {
                    selectedField = $(form).find('input[name$="-Key_ContactBy"]');
                    if (field.required === true) {
                        if (typeof(field.value) !== 'string' || field.value.length <= 0) {
                            errorFields.push(field.name);
                            selectedField.closest('.ctrl-holder').next('.field-error-message').show();
                        } else {
                            selectedField.closest('.ctrl-holder').next('.field-error-message').hide();
                        }
                    }
                }
            });

            if (data[6].value !== '') {
                if (!validText(data[6].value)) {
                    var firstName = $(form).find('[name="' + data[6].fieldName + '"]');
                    errorFields.push(data[6].name);
                    firstName.addClass('has-error');
                    firstName.closest('.ctrl-holder').next('.field-error-message').show();
                } else {
                    var firstName = $(form).find('[name="' + data[6].fieldName + '"]');
                    firstName.removeClass('has-error');
                    firstName.closest('.ctrl-holder').next('.field-error-message').hide();
                }
            }

            if (data[7].value !== '') {
                if (!validText(data[7].value)) {
                    var firstName = $(form).find('[name="' + data[7].fieldName + '"]');
                    errorFields.push(data[7].name);
                    firstName.addClass('has-error');
                    firstName.closest('.ctrl-holder').next('.field-error-message').show();
                } else {
                    var firstName = $(form).find('[name="' + data[7].fieldName + '"]');
                    firstName.removeClass('has-error');
                    firstName.closest('.ctrl-holder').next('.field-error-message').hide();
                }
            }

            //check if contact by phone or email
            if (typeof(data[2].value) === 'string' && data[2].value === 'yes') {
                if (typeof(data[3].value) === 'string' && data[3].value.length > 0) {
                    if (data[3].value === 'phone') {
                        if (validPhoneNUmber(data[9].value) === false) {
                            errorFields.push(data[9].name);
                            var phoneField = $(form).find('[name="' + data[9].fieldName + '"]');
                            phoneField.addClass('has-error');
                            phoneField.closest('.ctrl-holder').next('.field-error-message').show();
                        };
                    };

                    if (data[3].value === 'email') {
                        if (!validEmailAddress(data[10].value)) {
                            errorFields.push(data[10].name);
                            var emailField = $(form).find('[name="' + data[10].fieldName + '"]');
                            emailField.addClass('has-error');
                            emailField.closest('.ctrl-holder').next('.field-error-message').show();
                        };
                    };
                };
            };


            if (errorFields.length > 0) {
                errorMessage = errorFields.join() + ' fields are not valid';
                return {
                    isFormValid: false,
                    errorMessage: errorMessage
                };
            };

            data.forEach(function(field) {
                apiData[field.key] = field.value;
            });

            return {
                isFormValid: true,
                errorMessage: errorMessage,
                apiData: apiData
            };
        };

        resetIcons = function() {
            var listElements = $('#main-feedback li');
            var feedbackIcon = $('.tabs-nav .tab-label').find('img')[0];
            var complainIcon = $('.tabs-nav .tab-label').find('img')[1];
            listElements.removeClass('is-active');
            $(feedbackIcon).attr('src', SELECTORS.iconUrls.feedback.nonActive.dynamicPath);
            $(complainIcon).attr('src', SELECTORS.iconUrls.complaint.nonActive.dynamicPath);
        };

        toggleIcons = function() {
            $(SELECTORS.FORM_SECTION).on('click', '.tabs-nav ul li button', function() {

                //check if the form is open
                if ($(SELECTORS.FORM_SECTION).hasClass('close')) {
                    $(SELECTORS.FORM_SECTION).removeClass('close');
                    $(SELECTORS.FORM_SECTION).css({ transition :'right 250ms'});
                    $(SELECTORS.SCREEN_OVERLAY).show();
                };

                var selectedElement = $(this).parentsUntil('ul');

                var buttonId = $(this).attr('id');

                if (buttonId === 'tabbutton-give-complement') {
                    selectedElement.siblings('.tab-label-text-stored').find('span img')
                        .attr('src', SELECTORS.iconUrls.complaint.nonActive.dynamicPath);
                    $(this).find('span img')
                        .attr('src', SELECTORS.iconUrls.feedback.active.dynamicPath);
                };
                if (buttonId === 'tabbutton-make-complain') {
                    selectedElement.siblings('.tab-label-text-stored').find('span img')
                        .attr('src', SELECTORS.iconUrls.feedback.nonActive.dynamicPath);
                    $(this).find('span img')
                        .attr('src', SELECTORS.iconUrls.complaint.active.dynamicPath);

                };
            });

        };


        onSubmit = function() {
            $('.submitBtn').on('click', function() {
                var formDiv = $(this).parents('.tab.is-active');
                var formValidationData = validateForm(formDiv);
                $(formDiv).find('.errorMessage').html('');

                if (formValidationData.isFormValid && $('#GoogleCaptchaToken').val().length !== 0) {
                    var data = formValidationData.apiData;
                    $.ajax({
                        url:getDynamicAPI(),
                        type: 'POST',
                        data: data,
                        contentType: 'application/x-www-form-urlencoded; charset=utf-8',
                        dataType: 'json',
                        success: function(response, status) {
                            if (status === 'success' && response.status.toLowerCase() === 'ok') {
                                $(formDiv).find('.form-wrapper').hide();
                                if (data.Key_ContactMeBack === 'yes') {
                                    $(formDiv).find('.result').show();
                                    $(formDiv).find('.successYes').show();
                                } else {
                                    $(formDiv).find('.result').show();
                                    $(formDiv).find('.successNo').show();
                                }
                            }
                        },
                        error: function(xhr, status, error) {
                            if (status === 'error') {
                                console.log(error);
                                var finalErrorMessage = typeof (xhr.responseJSON.message) === 'string' && xhr.responseJSON.message.length > 0 ? xhr.responseJSON.message : 'Sorry !! Something went wrong';
                                $(formDiv).find('.errorMessage').html(finalErrorMessage);
                            }
                        }
                    });
                };

            });
        };

				/*
				* Invividual field validation
				* toggles error message based on validity
				* return isValid boolean
				* */

			function fieldValidation(form, fieldName) {

				var fieldValue = $(form).find('[name="' + fieldName + '"]').val();
				var isValid = true;

				if (fieldName === 'contact-phone' || fieldName === 'contact-email') {
					isValid = fieldName === 'contact-phone' ? !(validPhoneNUmber(fieldValue) === false) : !!validEmailAddress(fieldValue);
				} else {
					isValid = typeof fieldValue === 'string' && fieldValue.length > 0 && validText(fieldValue);
				}

				if (fieldName === 'product-area') {
					var selectedField = $(form).find('[name="' + fieldName + '"]');

					const position  = $(selectedField).prop('selectedIndex');
					if (position === 0) {
						isValid = false;
					} else {
						isValid = true;
					}
				}

				if (isValid === true) {
					var selectedField = $(form).find('[name="' + fieldName + '"]');
					selectedField.removeClass('has-error');
					selectedField.closest('.ctrl-holder').next('.field-error-message').hide();
				} else {
					var selectedField = $(form).find('[name="' + fieldName + '"]');
					selectedField.addClass('has-error');
					selectedField.closest('.ctrl-holder').next('.field-error-message').show();
				}

				return isValid;

			}

        /*
        * toggle internal forms like
        * for example step1 of the form and step2 of the form
        * */

        toggleInternalForms = function() {

            var buttons = $(SELECTORS.FORM_SECTION)
                .find('.tab').not('disabled')
                .find(SELECTORS.NEXT_BTN);

                $(buttons).each(function() {
                    $(this).on('click', function(e) {
                        var formDiv = $(this).parents('.tab.is-active');
												var isFncDetailsFieldValid = fieldValidation(formDiv, 'fnc-details');
												var isProductAreaFieldValid = fieldValidation(formDiv, 'product-area');

												if (isFncDetailsFieldValid && isProductAreaFieldValid) {
													//toggle process
													var step1Form = $(this).parents('.btn-group').siblings('.step1');
													var step2Form = $(this).parents('.btn-group').siblings('.step2');

													if (step1Form) {
														$(step1Form).toggle();
													};
													if (step2Form) {
														$(step2Form).toggle();
													};
													if (e.target.textContent === 'Next') {
														$(this).html('Prev');
														$(this).addClass('prev');
														$(this).parents('.btn-group').find(SELECTORS.SUBMIT_BTN).show();
													} else {
														$(this).html('Next');
														$(this).removeClass('prev');
														$(this).parents('.btn-group').find(SELECTORS.SUBMIT_BTN).hide();
													};
												}
                    });
                });
        };

        function validateRadioTypes(form) {
            var contactByValue = $(form).find('input[name$="-Key_ContactBy"]:checked').val();
            var selectedField = $(form).find('input[name$="-Key_ContactBy"]');
            if (typeof(contactByValue) !== 'string' || contactByValue.length <= 0) {
                selectedField.closest('.ctrl-holder').next('.field-error-message').show();
            } else {
                selectedField.closest('.ctrl-holder').next('.field-error-message').hide();
            }
        }

        init = function() {

            //hide second step of all forms

            $(document).ready(function($) {
                $('.cm-floating-form').find('#give-complement .step2').hide();
                $('.cm-floating-form').find('#make-complain .step2').hide();
                $('.cm-floating-form').find('#give-complement .result').hide();
                $('.cm-floating-form').find('#make-complain .result').hide();
                $('.cm-floating-form').find('.field-error-message').hide();
                $('.cm-floating-form').find('.thanku-page img').attr('src', SELECTORS.iconUrls.thankYouIcon.dynamicPath);

                $('.cm-floating-form').find('ul').children().first().removeClass('is-active');
                $('.cm-floating-form').find('.closeBtn').on('click', function() {
                    if (!$(SELECTORS.FORM_SECTION).hasClass('close')) {
                        $(SELECTORS.FORM_SECTION).addClass('close');
                        resetIcons();
                    };
                });
                $('.overlay-for-feedbackwidget').on('click',function() {
                    $(SELECTORS.SCREEN_OVERLAY).hide();
                    $(SELECTORS.FORM_SECTION).addClass('close');
                });
            });
            $(document).mouseup(function(e) {
                var container = $(SELECTORS.FORM_SECTION);

                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    $(SELECTORS.FORM_SECTION).addClass('close');
                    $(SELECTORS.SCREEN_OVERLAY).hide();
                    resetIcons();
                };

            });

            $('input').blur(function(e) {
                if (e.target.type !== 'radio') {
                    var formDiv = $(this).parents('.tab.is-active');
                    fieldValidation(formDiv,e.target.name);
                }
            });

            $('textarea').blur(function(e) {
                var formDiv = $(this).parents('.tab.is-active');
                fieldValidation(formDiv,e.target.name);
            });

            $('select').blur(function(e) {
                var formDiv = $(this).parents('.tab.is-active');
                fieldValidation(formDiv,e.target.name);
            });

            $(SELECTORS.FORM_SECTION).find('[name$="-Key_ContactBy"]').parentsUntil('ul').on('click', function() {
                var formDiv = $(this).parents('.tab.is-active');
                validateRadioTypes(formDiv);
            });

            toggleInternalForms();
            toggleButtons();
            toggleIcons();
            detectFieldChanges();
            onSubmit();
        };

        return {
            init: init,
            toggleInternalForms: toggleInternalForms,
            toggleIcons: toggleIcons,
            toggleButtons: toggleButtons,
            resetIcons:resetIcons,
            detectFieldChanges: detectFieldChanges,
            onSubmit: onSubmit
        };
    }());

    // HIDE CLOSE BUTTON
    DDIGITAL.floatingFeedbackForm.init();

}(DDIGITAL, jQuery));
