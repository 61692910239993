/* =============================================================================
   OFFSCREEN NAV
   ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.navOffscreen = (function() {
		var SELECTORS,
			CLASSES,
			_navPrev,
			_navNext,
			_setParentNavOverflow,
			_setA11y,
			_closeLoginMenu,
			init;

		SELECTORS = {
			OFFSCREEN_CONTAINER: '.offscreen-container',
			OFFSCREEN: '.js-offscreen',
			OFFSCREEN_TOGGLE: '.js-offscreen-toggle',
			OFFSCREEN_CONTENT: '.offscreen-content',
			NAV_CONTENT: '.nav-content',
			NAV_PREV: '.nav-prev',
			NAV_NEXT: '.nav-next',
			IS_ACTIVE: '.is-active',
			IS_EXPANDED: '.is-expanded',
			NAV_CHILDREN: '.nav-children',
			POPOVER_TOGGLE: '.js-popover-toggle',
			NAV_LOGIN: '.nav-login',
			GLOBAL_SEARCH:'#global-search',
			HOMEPAGE_USER_TASK_NAV:'.homepage-user-task-nav'
		};

		CLASSES = {
			IS_ACTIVE: 'is-active',
			IS_EXPANDED: 'is-expanded',
			IS_READY:'is-ready'
		};

		/**
		 * Collapse login menu
		 * @method _closeLoginMenu
		 * @private
		 */
		_closeLoginMenu = function() {
			if ($(SELECTORS.NAV_LOGIN).find('.login-trigger').hasClass(CLASSES.IS_EXPANDED)) {
				$(SELECTORS.NAV_LOGIN).find('.login-trigger').next('.expandcollapse-content').trigger('collapse');
			};
		};

		/**
		 * Go back one level of navigation
		 * @method _navPrev
		 * @param {object} $el Clicked link element
		 * @private
		 */
		_navPrev = function($el) {

			var $parentNav = $el.parents('ul').parents('.is-expanded');
			var $closestNavChildren = $el.closest(SELECTORS.NAV_CHILDREN);

			$(SELECTORS.OFFSCREEN)
				.find(SELECTORS.NAV_CONTENT)
					.height($parentNav.height());

			$closestNavChildren.removeClass(CLASSES.IS_EXPANDED);

			$(SELECTORS.OFFSCREEN_CONTENT).animate({
				scrollTop: 0
			}, 250, function() {
				$closestNavChildren.find('> li > a').addBack().attr({
					'aria-hidden': true,
					'tabindex': '-1'
				});
				$closestNavChildren.find('> li > a.nav-prev').attr({
					'aria-expanded': false
				});

				$closestNavChildren.prev(SELECTORS.NAV_NEXT).parent('li').siblings('li').find('> a').attr({
					'aria-hidden': false
				})
				.removeAttr('tabindex');

				if (!$parentNav.length) {

					$(SELECTORS.OFFSCREEN)
						.find(SELECTORS.NAV_CONTENT)
							.removeAttr('style');
				}

				$closestNavChildren.prev(SELECTORS.NAV_NEXT).attr({
					'aria-hidden': false,
					'aria-expanded': false
				})
				.removeAttr('tabindex')
				.addClass('what')
				.focus();
			});

			_closeLoginMenu();
		};

		/**
		 * Go forward one level of navigation
		 * @method _navNext
		 * @param {object} $el Clicked link element
		 * @private
		 */
		_navNext = function($el) {

			var $navChildren = $el.next(SELECTORS.NAV_CHILDREN);

			$(SELECTORS.OFFSCREEN).find(SELECTORS.NAV_CONTENT).height($navChildren.height());

			$el.next(SELECTORS.NAV_CHILDREN).addClass(CLASSES.IS_EXPANDED);

			$(SELECTORS.OFFSCREEN_CONTENT).animate({
				scrollTop: 0
			}, 250, function() {
				$navChildren.find('> li > a').addBack().attr({
					'aria-hidden': false
				})
				.removeAttr('tabindex');
				$navChildren.find('> li > a.nav-prev').attr({
					'aria-expanded': true
				});

				$el.attr({
					'aria-hidden': true,
					'aria-expanded': true,
					'tabindex': '-1'
				})
				.parent('li').siblings('li').find('> a').attr({
					'aria-hidden': true,
					'tabindex': '-1'
				});

				$navChildren.find('a').first().focus();
			});

			_closeLoginMenu();
		};

		/**
		 * Set parent nav overflow
		 * @method _setParentNavOverflow
		 * @private
		 */
		_setParentNavOverflow = function() {
			var $parentNav = $(SELECTORS.OFFSCREEN).find(SELECTORS.IS_ACTIVE).last().parent(SELECTORS.NAV_CHILDREN);

			$(SELECTORS.OFFSCREEN).find(SELECTORS.NAV_CONTENT).height($parentNav.height());
		};

		/**
		 * Set a11y features
		 * @method _setA11y
		 * @private
		 */
		_setA11y = function() {
			$(SELECTORS.OFFSCREEN).find(SELECTORS.NAV_CHILDREN).find('a').addBack().attr({
				'aria-hidden': true,
				'tabindex': '-1'
			});

			if ($(SELECTORS.OFFSCREEN).find(SELECTORS.IS_ACTIVE).length) {

				$(SELECTORS.OFFSCREEN).find(SELECTORS.NAV_CONTENT).find('.nav-lvl1').attr({
					'aria-hidden': true,
					'tabindex': '-1'
				});

				$(SELECTORS.OFFSCREEN).find(SELECTORS.IS_ACTIVE).last().closest(SELECTORS.IS_EXPANDED)
				.find('> li > a').addBack().attr({
					'aria-hidden': false
				})
				.removeAttr('tabindex');
			}
		};

		// initialiser
		init = function() {
			_setA11y();

			// if you use animationType: 'side' you need to move the shade inside the page wrap
			$(SELECTORS.OFFSCREEN)
				.ddOffscreen({
					animationType: 'slideUp',
					navWidth: '100%',
					durations: {
						show: 250,
						hide: 250
					},
					offscreenContainer: '.offscreen-container'
				})
				.on('open.ddOffscreen', function() {
					_setParentNavOverflow();
					$('.mobile-toolbar').hide().show(0);
					$(SELECTORS.POPOVER_TOGGLE).removeClass(CLASSES.IS_ACTIVE);
					$(SELECTORS.GLOBAL_SEARCH).removeClass(CLASSES.IS_ACTIVE + ' ' + CLASSES.IS_READY);
					// remove aria attributes to allow focus outside modal
					$(SELECTORS.GLOBAL_SEARCH).removeAttr('aria-modal');
					$(SELECTORS.HOMEPAGE_USER_TASK_NAV).removeAttr('aria-hidden');
				})
				.on('close.ddOffscreen', function() {
					$(SELECTORS.OFFSCREEN_TOGGLE).removeClass(CLASSES.IS_ACTIVE);
				});

			$(SELECTORS.OFFSCREEN).find(SELECTORS.NAV_PREV)
				.off('click.nav-prev')
				.on('click.nav-prev', function(e) {
					e.preventDefault();
					_navPrev($(this));
				});

			$(SELECTORS.OFFSCREEN).find(SELECTORS.NAV_NEXT)
				.off('click.nav-next')
				.on('click.nav-next', function(e) {
					e.preventDefault();
					_navNext($(this));
				});

			$(SELECTORS.POPOVER_TOGGLE).on('click', function() {
				$(SELECTORS.OFFSCREEN).trigger('close.ddOffscreen');
			});

			enquire.register(DD.bp.get('l'), {
				match: function() {
					$(SELECTORS.OFFSCREEN).trigger('close.ddOffscreen');
					$('.mobile-toolbar').removeAttr('style');

					NAMESPACE.primaryNavBrowserView.toggleStickyNav();
				}
			});
		};

		return {
			init: init
		};

	}());

}(DDIGITAL, jQuery));
