/* ==========================================================================
 * TOGGLE SEARCH BAR
 * ========================================================================== */

(function(NAMESPACE, $) {

	'use strict';

	NAMESPACE.togglePopover = (function() {

		var ATTRS,
			CLASSES,
			SELECTORS,
			_togglePopover,
			_toggleSearchBtn,
			_showPopover,
			_hidePopover,
			init;

		ATTRS = {
			POPOVER_ID: 'data-popover-id',
			IS_ANIMATING: 'popover-is-animating'
		};

		CLASSES = {
			IS_ACTIVE: 'is-active',
			IS_READY: 'is-ready'
		};

		SELECTORS = {
			TOGGLE: '.js-popover-toggle',
			GLOBAL_SEARCH:'#global-search',
			HOMEPAGE_USER_TASK_NAV:'.homepage-user-task-nav',
			SEARCH_BTN: '.search-submit',
			SEARCH_INPUT: '.js-autocomplete-input'
		};

		_hidePopover = function($popover, $button) {
			if ($popover.data(ATTRS.IS_ANIMATING) === true) {
				return;
			}

			$popover.data(ATTRS.IS_ANIMATING, true);

			$button.removeClass(CLASSES.IS_ACTIVE);

			$popover.removeClass(CLASSES.IS_ACTIVE)
				.find('input[type="search"]').val('');
			$(SELECTORS.SEARCH_BTN).prop('disabled',true);
			$(SELECTORS.SEARCH_INPUT).attr('aria-expanded', 'false');
			DD.a11y.tabInsideContainer.unset();
			DD.a11y.onEscape.unset();
			DD.a11y.onClickOutside.unset();

			// Toggle Floating CTA button
			if (DDIGITAL.floatingBtn && DDIGITAL.floatingBtn.toggleFloatingBtnDisable) {
				DDIGITAL.floatingBtn.toggleFloatingBtnDisable('close');
			}

			// focus back on the original button when closing
			$button.focus();

			// if the button is in autocomplete component, focus back to parent button
			if ($button.hasClass('search-close')) {
				DD.noScroll.unset();
				$('.search-toggle').focus();
			} else if ($button.hasClass('search-toggle')) {
				DD.noScroll.unset();
			};

			// hide shade
			if ($.ddShade.isAnimating()) {
				// if the shade is already animating we shouldn't hide it
				$.ddShade.setBehindHeader(false);
				$popover.removeClass(CLASSES.IS_READY);
				$popover.data(ATTRS.IS_ANIMATING, false);
			} else {
				// fade out and hide the shade entirely
				$.ddShade.opacity(0, 150, true, function() {
					$.ddShade.setActive(false);
					$.ddShade.setBehindHeader(false);

					$popover.removeClass(CLASSES.IS_READY);

					$popover.data(ATTRS.IS_ANIMATING, false);
				});
			}
		};

		_showPopover = function($popover, $button) {

			if ($button.hasClass('search-toggle')) {
				DD.noScroll.set();
				NAMESPACE.primaryNavBrowserView.closeNav();
			};

			if ($popover.data(ATTRS.IS_ANIMATING) === true) {
				return;
			}

			$popover.data(ATTRS.IS_ANIMATING, true);

			var hideCurrentPopover = function() {
				_hidePopover($popover, $button);
			};

			// add class to toggle display state
			$popover.addClass(CLASSES.IS_READY);
			$button.addClass(CLASSES.IS_ACTIVE);
			//add aria attribute to keep focus in the modal
			$(SELECTORS.GLOBAL_SEARCH).attr('aria-modal', 'true');
			$(SELECTORS.HOMEPAGE_USER_TASK_NAV).attr('aria-hidden', 'true');
			// add aria attribute to show status of modal
			$(SELECTORS.SEARCH_INPUT).attr('aria-expanded', 'true');

			// setup shade
			$.ddShade.setBehindHeader(true);
			$.ddShade.setActive(true);

			// bind close to escape button
			DD.a11y.onEscape.set(hideCurrentPopover);

			// bind close on outside click
			DD.a11y.onClickOutside.set($popover, hideCurrentPopover);

			// display shade
			$.ddShade.opacity(0.75, 150, false, function() {
				$popover.addClass(CLASSES.IS_ACTIVE);

				DD.a11y.tabInsideContainer.set($popover, true);
				$popover.find('input[type="search"]').focus();

				$popover.data(ATTRS.IS_ANIMATING, false);
			});

			// Toggle Floating CTA button
			if (DDIGITAL.floatingBtn && DDIGITAL.floatingBtn.toggleFloatingBtnDisable) {
				DDIGITAL.floatingBtn.toggleFloatingBtnDisable('open');
			}
		};

		_togglePopover = function(event) {
			event.preventDefault();

			var $button = $(this),
				popoverId = $button.attr(ATTRS.POPOVER_ID),
				$popover = $(document.getElementById(popoverId));

			if ($popover.hasClass(CLASSES.IS_ACTIVE)) {
				_hidePopover($popover, $button);

				return;
			}

			_showPopover($popover, $button);
		};

		_toggleSearchBtn = function(event) {
			event.preventDefault();
			if ($(this).val().length > 0) {
				$(SELECTORS.SEARCH_BTN).prop('disabled',false);
			} else {
				$(SELECTORS.SEARCH_BTN).prop('disabled',true);
			}
		};

		init = function() {
			$(document).on('click.togglePopover', SELECTORS.TOGGLE, _togglePopover);
			$(SELECTORS.SEARCH_INPUT).on('input', _toggleSearchBtn);
		};

		return {
			init: init
		};
	}());

}(DDIGITAL, jQuery));
